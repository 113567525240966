import { Injectable } from '@angular/core';
import { Observable, filter } from 'rxjs';

import { CommonFunctions, MdlBaseObject } from '@dicorp/zappsmith-ngx-core';

import { ZappsmithWebService, ZappsmithWebServiceApiParams } from '@dicorp/zappsmith-ngx-core';
import { BaseZappsmithBoard, BaseZappsmithStoreState, BaseZappsmithComponentStore } from '@dicorp/zappsmith-ngx-core';

import { ZappApp, ZappAppStore } from './zapp-app.store';
import { ZappAppBoard, ZappAppBoardStore } from './zapp-app-board.store';

function getZappCustomToolbarBoardParams(): ZappsmithWebServiceApiParams {
    const apiParams = new ZappsmithWebServiceApiParams('zapp_custom_toolbar');
    apiParams.setQuery({ projection: { 'tracking_info': 0 } });
    return apiParams;
}

enum ZappCustomToolbarPlacement {
    above = 'above',
    below = 'below'
}

interface ZappCustomToolbarBoardLink {
    BoardLink: MdlBaseObject;
}

interface ZappCustomToolbarLink {
    ZappCustomToolbarLink: MdlBaseObject;
}

export class ZappCustomToolbar extends BaseZappsmithBoard {
    public Label: string;
    public IsDisabled: boolean;
    public SortOrder: number;
    public Placement: ZappCustomToolbarPlacement;

    public ApplicationLink: MdlBaseObject;
    public ApplicationLinkValue: string;

    public Boards: ZappCustomToolbarBoardLink[];
    public ParentCustomToolbars: ZappCustomToolbarLink[];

    constructor(rawZappApp: any) {
        super(rawZappApp, 'ZappCustomToolbar.Id', 'ZappCustomToolbar.Name');

        this.Label = CommonFunctions.resolvePathValue('ZappCustomToolbar.Label', rawZappApp);
        this.SortOrder = CommonFunctions.resolvePathValue('ZappCustomToolbar.SortOrder', rawZappApp);
        this.IsDisabled = CommonFunctions.resolvePathValue('ZappCustomToolbar.IsDisabled', rawZappApp);
        this.Placement = CommonFunctions.resolvePathValue('ZappCustomToolbar.Placement', rawZappApp);

        this.ApplicationLink = CommonFunctions.resolvePath('ZappCustomToolbar.ApplicationLink', rawZappApp);
        this.ApplicationLinkValue = CommonFunctions.resolvePathValue('ZappCustomToolbar.ApplicationLink', rawZappApp);

        this.Boards = CommonFunctions.resolvePath('ZappCustomToolbar.Boards', rawZappApp);
        this.ParentCustomToolbars = CommonFunctions.resolvePath('ZappCustomToolbar.ParentCustomToolbars', rawZappApp);
    }
}

export interface ZappToolbar {
    _id?: string;

    Label: string;
    IsDisabled: boolean;
    SortOrder?: number;

    Children?: ZappToolbar[];

    ZappApp?: ZappApp;
    ZappAppBoards?: ZappAppBoard[];

    isPopupOpen?: boolean;
    contextMenuOpen?: boolean;

    toolbarMouseEnter?: boolean;
    popupMouseEnter?: boolean;
}

interface ZappToolbarState extends BaseZappsmithStoreState {
    zappToolbars?: ZappToolbar[];
    zappToolbarsLoaded?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ZappToolbarStore extends BaseZappsmithComponentStore<ZappToolbarState> {

    readonly zappToolbars$: Observable<ZappToolbar[]> = this.select(state => state.zappToolbars);

    private zappCustomToolbars: ZappCustomToolbar[] = [];
    private zappApps: ZappApp[];

    private zappToolbars: ZappToolbar[] = [];
    private zappToolbarsLoaded: boolean;

    constructor(
        private zappsmithWebService: ZappsmithWebService,
        private zappAppStore: ZappAppStore,
        private zappAppBoardStore: ZappAppBoardStore) {
        super();
        this.setState({});

        zappAppStore.zappAppsState$.subscribe(zappAppsState => {
            if (zappAppsState?.zappAppsLoaded && zappAppsState?.zappAppBoardsLoaded) {
                this.zappApps = zappAppsState.zappApps;
                this.getZappCustomToolbars();
            }
        });
    }

    private getZappCustomToolbars(): void {
        const zappCustomToolbarBoardParams = getZappCustomToolbarBoardParams();
        zappCustomToolbarBoardParams.setOrderBy({ 'ZappCustomToolbar.SortOrder.#value': 'desc' });

        this.zappsmithWebService.getApi(zappCustomToolbarBoardParams).then(
            result => {
                const zappResult = this.convertGetBoardResults(ZappCustomToolbar, result);
                this.zappCustomToolbars = zappResult.data;

                this.updateZappToolbars();
            },
            () => {
            });
    }

    private updateZappToolbars(): void {
        this.zappToolbars = [];

        this.addZappAppToolbars();
        this.addZappCustomToolbars();

        this.zappToolbarsLoaded = true;

        this.patchState({
            zappToolbars: this.zappToolbars,
            zappToolbarsLoaded: this.zappToolbarsLoaded
        });
    }

    private addZappAppToolbars(): void {
        for (var zappApp of this.zappApps) {
            const zappToolbar: ZappToolbar = {
                Label: zappApp?.Label ? zappApp?.Label : zappApp?.Name,
                IsDisabled: zappApp.IsDisabled,
                ZappApp: zappApp,
                ZappAppBoards: [...zappApp.ZappAppBoards]
            }

            this.zappToolbars.push(zappToolbar)
        }
    }

    private addZappCustomToolbars(): void {
        this.addApplicationLinkCustomToolbars();
        this.addParentLinkCustomToolbars();
    }

    private addApplicationLinkCustomToolbars(): void {
        // Add Application Link Toolbars
        for (var zappCustomToolbar of this.zappCustomToolbars) {
            if (zappCustomToolbar.ApplicationLinkValue && !zappCustomToolbar.IsDisabled) {
                const zappApplicationToolbar = this.getZappToolbarByApplicationKey(zappCustomToolbar.ApplicationLinkValue);
                let zappApplicationToolbarIndex = this.zappToolbars.indexOf(zappApplicationToolbar);

                if (zappCustomToolbar.Placement === ZappCustomToolbarPlacement.below) {
                    zappApplicationToolbarIndex++;
                }

                const zappToolbar = this.createZappToolbarFromCustom(zappCustomToolbar);

                // Insert the Custom Toolbar
                this.zappToolbars.splice(zappApplicationToolbarIndex, 0, zappToolbar);
            }
        }
    }

    private addParentLinkCustomToolbars(): void {
        // Add Parent Link Custom Toolbars
        for (var zappCustomToolbar of this.zappCustomToolbars) {
            if (zappCustomToolbar.ParentCustomToolbars?.length > 0 && !zappCustomToolbar.IsDisabled) {
                for (var parentCustomToolbar of zappCustomToolbar.ParentCustomToolbars) {
                    const zappToolbar = this.createZappToolbarFromCustom(zappCustomToolbar);
                    const parentKey = CommonFunctions.getMdlObjectValue(parentCustomToolbar?.ZappCustomToolbarLink);
                    const zappToolbarParent = this.getZappToolbar(parentKey);

                    zappToolbarParent?.Children?.push(zappToolbar);
                }
            }
        }
    }

    private createZappToolbarFromCustom(zappCustomToolbar: ZappCustomToolbar): ZappToolbar {
        const zappAppBoardIds = zappCustomToolbar?.Boards?.map(board => {
            return CommonFunctions.getMdlObjectValue(board.BoardLink);
        })
        const zappAppBoards = this.zappAppBoardStore.getZappAppBoardsByIds(zappAppBoardIds);

        // Sort by the order the keys are in
        zappAppBoards.sort((a, b) => {
            return zappAppBoardIds.indexOf(a._id) - zappAppBoardIds.indexOf(b._id);
        });

        // Remove the boards from the all application toolbars
        for (var zappAppBoard of zappAppBoards) {
            const zappApplicationToolbarToUpdate = this.getZappToolbarByApplicationKey(zappAppBoard.ApplicationLinkValue);
            if (zappApplicationToolbarToUpdate) {
                zappApplicationToolbarToUpdate.ZappAppBoards = zappApplicationToolbarToUpdate.ZappAppBoards.filter(zappAppBoard => {
                    return zappAppBoardIds.indexOf(zappAppBoard._id) < 0;
                })
            }
        }

        const zappToolbar: ZappToolbar = {
            _id: zappCustomToolbar?._id,
            Label: zappCustomToolbar?.Label,
            IsDisabled: zappCustomToolbar.IsDisabled,
            SortOrder: zappCustomToolbar.SortOrder,
            ZappAppBoards: zappAppBoards,
            Children: []
        }

        return zappToolbar;
    }

    private getZappToolbarByApplicationKey(_id: string): ZappToolbar {
        return this.zappToolbars?.find(zappToolbar => {
            return zappToolbar.ZappApp?._id === _id;
        });
    }

    private getZappCustomToolbar(_id: string): ZappCustomToolbar {
        if (!_id) {
            return undefined;
        }

        return this.zappCustomToolbars?.find(zappCustomToolbar => {
            return zappCustomToolbar._id === _id;
        });
    }

    private getZappToolbar(_id: string): ZappToolbar {
        if (!_id) {
            return undefined;
        }

        return this.zappToolbars?.find(zappToolbar => {
            return zappToolbar._id === _id;
        });
    }
}